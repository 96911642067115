
(function() {

    "use strict";

    $(window).load(function() {
        $('body').addClass('preload-complete');
    });

})();
